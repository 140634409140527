



















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Currency from '@/model/currency';
@Component({
  components: {
  }
})
export default class InsOrderDetail extends Vue {
  newarry: any[] = [];
  OrderId: any = '';
  OrderDetail: any[] = [];
  Currency = Currency;
  // 获取订单传值和显示数据
  getOrderDetail () {
    let that = this;
    let OrderId = that.$route.params.id;
    that.$Api.order.getOrderDetail(OrderId).then(result => {
      that.OrderDetail = result;
      this.$HiddenLayer();
    });
  }
  // 支付打开页面
  PayNow (order) {
    // let routeData = this.$router.resolve({
    //   path: './payment/' + order.PaymentMethod + '/',
    //   name: 'payment',
    //   params: { id: order.OrderId }
    // });
    // window.open(routeData.href, '_blank');

    let url = '/PG/pay/' + order.PaymentMethod + '/IS/' + order.OrderId;
    window.open(url, '_blank');
  }

  // 立即关闭按钮
  closePage () {
    this.$router.push('/account/memberCentral?to=order');
  }

  // 页面创建成功时加载数据
  created () {
    this.getOrderDetail();
  }
}
