








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Form as ElForm, Input, Message } from 'element-ui';
import sdk from '@/sdk/InstoreSdk';
// import EdNavaccount from '@/components/fsBusiness/pc/account/EdNavaccount.vue';
// import profile from '@/components/fsBusiness/pc/account/FsProfile.vue';
// import notification from '@/components/business/pc/account/InsNotification.vue';
// import coupon from '@/components/business/pc/account/InsMyCoupon.vue';
// import order from '@/components/business/pc/account/InsOrderList.vue';
// import favorite from '@/components/business/pc/account/InsMyFavorite.vue';
// import points from '@/components/business/pc/account/InsPoints.vue';
// import deliverAddress from '@/components/business/pc/account/InsDeliveryAddress.vue';
@Component({
  components: {
    EdNavaccount: () => import(/* webpackChunkName: "account" */'@/components/fsBusiness/pc/account/EdNavaccount.vue'),
    profile: () => import(/* webpackChunkName: "account" */'@/components/fsBusiness/pc/account/FsProfile.vue'),
    notification: () => import(/* webpackChunkName: "account" */'@/components/business/pc/account/InsNotification.vue'),
    coupon: () => import(/* webpackChunkName: "account" */'@/components/business/pc/account/InsMyCoupon.vue'),
    order: () => import(/* webpackChunkName: "account" */'@/components/business/pc/account/InsOrderList.vue'),
    favorite: () => import(/* webpackChunkName: "account" */'@/components/business/pc/account/InsMyFavorite.vue'),
    points: () => import(/* webpackChunkName: "account" */'@/components/business/pc/account/InsPoints.vue'),
    deliverAddress: () => import(/* webpackChunkName: "account" */'@/components/business/pc/account/InsDeliveryAddress.vue')
  }
})
export default class InsMemberInfo extends Vue {
  active = 'profile';
  change (active) {
    if (this.active === active) return;
    this.$ShowLayer();
    setTimeout(() => { this.active = active; }, 1000);
  }
  created () {
    if (this.$route.query.to) { this.change(this.$route.query.to); }
  }
  @Watch('$route', { deep: true })
  onToChange () {
    if (!this.$route.query.to && this.$route.query.to === this.active) return;
    this.active = this.$route.query.to as string;
  }
}
