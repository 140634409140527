








































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Form as ElForm, Input, Message } from 'element-ui';
import sdk from '@/sdk/InstoreSdk';
@Component({
  components: {
  }
})
export default class InsMemberInfo extends Vue {
  activeName: string = 'first';
  // 验证规则
  ruleForm: any = {
    FirstName: '',
    LastName: '',
    ContactNumber: '',
    BirthMD: '',
    Gender: '',
    Mobile: '',
    Language: ''
  };
  lang:string[] = ['E', 'C', 'S'];
  validatePass (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('MemberInfo.EnterUserName') as string));
    } else {
      callback();
    }
  }
  validatePass2 (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('MemberInfo.EnterComfirmPwd') as string));
    } else if (value !== this.pwdForm.NewPassword) {
      callback(new Error(this.$t('MemberInfo.PwdNotCorrect') as string));
    } else {
      callback();
    }
  }
  get rules () {
    return {
      FirstName: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserName'),
          trigger: 'blur'
        }
      ],
      LastName: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserLastName'),
          trigger: 'blur'
        }
      ],
      ContactNumber: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserPhone'),
          trigger: 'blur'
        }
      ]
      // BirthMD: [
      //   {
      //     type: 'date',
      //     required: true,
      //     message: this.$t('MemberInfo.EnterUserBrithDate'),
      //     trigger: 'change'
      //   }
      // ]
    };
  }

  pwdForm: any = {
    Password: '',
    NewPassword: '',
    ConfirmNewPassword: ''
  };
  get pwdRules () {
    var that = this;
    return {
      Password: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterOldPwd'),
          trigger: 'blur'
        }
      ],
      NewPassword: [
        {
          required: true,
          message: '',
          trigger: 'blur',
          validator: that.validatePass
        }
      ],
      ConfirmNewPassword: [
        {
          required: true,
          message: '',
          trigger: 'blur',
          validator: that.validatePass2
        }
      ]
    };
  }
  getProfile () {
    let _this = this;
    sdk.api.member.getProfile().then(
      (data) => {
        if (data) {
          _this.ruleForm = data;
          _this.$store.dispatch('setUser', (data.FirstName + ' ' + data.LastName).toUpperCase());
          _this.$i18n.locale = _this.lang[data.Language];
          this.$HiddenLayer();
        } else {
          _this.$store.dispatch('Logout');
        }
      },
      (data) => {
        this.$HiddenLayer();
        _this.$message({
          message: data,
          type: 'error'
        });
      }
    );
  }
  mounted () {
    this.getProfile();
  }

  // 提交用户信息表单
  SubmitForm (ruleForm) {
    let that = this;
    this.$nextTick(function () {
      (that.$refs.ruleForm as ElForm).validate(valid => {
        if (valid) {
          sdk.api.member.updateProfile(this.ruleForm).then(
            function (response) {
              that.$message({
                message: response.Message,
                type: 'success'
              });

            // _this.$router.push('/account/registerSuccess');
            },
            function (response) {
              that.$message({
                message: response.Message,
                type: 'error'
              });
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    });
  }
  // 重置用户信息表单
  ResetForm (ruleForm) {
    (this.$refs.ruleForm as ElForm).resetFields();
  }
  // 提交密码修改表单
  CheckPwdSubmit (pwdForm) {
    let that = this;
    this.$nextTick(function () {
      (that.$refs.pwdForm as ElForm).validate(valid => {
        if (valid) {
          sdk.api.member.updatePassword(this.pwdForm).then(
            function (response) {
              console.log(response.Message, '22222');
              that.$message({
                message: response.Message,
                type: 'success'
              });

            // _this.$router.push('/account/registerSuccess');
            },
            function (response) {
              console.log(response.Message, '3333333333333');
              that.$message({
                message: response.Message,
                type: 'error'
              });
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    });
  }
  // 重置用户密码表单
  ResetPwdForm (pwdForm) {
    (this.$refs.pwdForm as ElForm).resetFields();
  }
}
