











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InsButton from '@/components/base/pc/InsButton.vue';
import Order from '@/model/order';
import PromotionDiscount from '@/model/PromotionDiscount';
@Component({ components: { InsButton } })
export default class InsCompleteCheckout extends Vue {
    private Order:Order =new Order();
    get orderId () {
      return this.$route.params.id;
    }
    created () {
    //   if (!this.orderId && !this.$route.params.id) throw new Error('need order ID');
      if (this.orderId) {
        this.$Api.order.getOrder(this.orderId).then((result) => {
          this.Order = result.Order;
          this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
          this.$store.dispatch('setPromotionDiscount', new PromotionDiscount());
        });
      }
    }
    @Watch('orderId')
    onOrderId () {
      this.$Api.order.getOrder(this.orderId).then((result) => {
        this.Order = result.Order;
        this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
      });
    }
    pay (pay) {
      window.open('/PG/pay/' + this.Order.PaymentMethod + (this.Order.PaymentMethod === 'Stripe' ? '/' : '/IS/') + this.orderId);
    }
}
