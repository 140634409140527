






import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import Product from '@/model/youWouldLike';
// import inProductWindow from '@/components/business/pc/product/InsProductItem.vue';
@Component({ components: { inProductWindow: () => import(/* webpackChunkName: "product" */'@/components/business/pc/product/InsProductItem.vue') } })
export default class InsProductList extends Vue {
    @Prop() private readonly column!: number;
    @Prop() private readonly allItems!: Product[];
}
