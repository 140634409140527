










import { Vue, Prop, Component } from 'vue-property-decorator';
import MemberInfo from '@/components/business/pc/account/InsMemberInfo.vue';
import MyCoupon from '@/components/business/pc/account/InsMyCoupon.vue';
import MyFavorite from '@/components/business/pc/account/InsMyFavorite.vue';
import Notification from '@/components/business/pc/account/InsNotification.vue';
import OrderList from '@/components/business/pc/account/InsOrderList.vue';
import DeliveryAddress from '@/components/business/pc/account/InsDeliveryAddress.vue';
@Component({ components: { MemberInfo, MyCoupon, MyFavorite, Notification, OrderList, DeliveryAddress } })
export default class InsMemberCentra extends Vue {
  private componentNames:string[] = ['MemberInfo', 'MyCoupon', 'MyFavorite', 'Notification', 'OrderList', 'DeliveryAddress'];
  get mode () {
    return this.vw >= 1920 ? 'vertical' : 'horizontal';
  }
  get currentTabComponent () {
    return this.$route.params.location ? this.$route.params.location : 'MemberInfo';
  }
  created () {
    this.$HiddenLayer();
  }
}
