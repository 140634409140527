















import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
// import InsNotification from '@/components/business/pc/account/InsNotification.vue';
// import InsOrderList from '@/components/business/pc/account/InsOrderList.vue';
// import InsMyFavorite from '@/components/business/pc/account/InsMyFavorite.vue';
// import InsMemberPoints from '@/components/business/pc/account/InsPoints.vue';
// import InsMyCoupon from '@/components/business/pc/account/InsMyCoupon.vue';
// import InsProfile from '@/components/edBusiness/pc/account/InsProfile.vue';
// import Botton from '@/components/base/pc/InsButton.vue';
@Component({ components: { FsMemberCentral: () => import(/* webpackChunkName: "account" */'@/components/fsBusiness/pc/account/FsMemberCentral.vue') } })
export default class InsMemberCentral extends Vue {
  private to:string = 'InsProfile';
  To (path) {
    if (this.to === path) return;
    this.$ShowLayer();
    this.to = path;
  }
  created () {
    if (this.$route.query.to) { this.To(this.$route.query.to); }
  }
  @Watch('$route', { deep: true })
  onRouterChange () {
    if (this.$route.query.to) { this.To(this.$route.query.to); } else { this.To('InsProfile'); }
  }
}
