import { render, staticRenderFns } from "./EdNavaccount.vue?vue&type=template&id=fd661084&scoped=true&"
import script from "./EdNavaccount.vue?vue&type=script&lang=ts&scoped=true&"
export * from "./EdNavaccount.vue?vue&type=script&lang=ts&scoped=true&"
import style0 from "./EdNavaccount.vue?vue&type=style&index=0&id=fd661084&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd661084",
  null
  
)

export default component.exports