





















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsRegisterSuccess extends Vue {
  handleCommand () {
    window.location.href = ('/account/login');
  }
  created () {
    this.$HiddenLayer();
  }
};

