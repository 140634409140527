



















import { Component, Prop, Vue } from 'vue-property-decorator';
import sdk from '@/sdk/InstoreSdk';
@Component
export default class accountHeader extends Vue {
   TotalPoints:string='';
   MemberName:string='';
   // 获取会员信息
   // 获取会员总积分
   getMemberTotal () {
     this.$Api.pointsApi.GetMemberTotalPointsInfo().then((result) => {
       this.TotalPoints = result.data.TotalPoints;
       this.MemberName = result.data.MemberName;
     });
   }
   mounted () {
     this.getMemberTotal();
   }
   emit (to) {
     this.$emit('change', to);
   }
}
