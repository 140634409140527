













































































































































import { Vue, Prop, Component } from 'vue-property-decorator';
import EdNavaccount from '@/components/fsBusiness/pc/account/FsNavaccount.vue';
import { Form as ElForm, Input, Message } from 'element-ui';
import { mount } from '@vue/test-utils';
@Component
export default class FsProfile extends Vue {
    activeName: string = 'first';
  // 验证规则
  private bd = new Date();
  ruleForm: any = {
    FirstName: '',
    LastName: '',
    ContactNumber: '',
    BirthMD: '',
    Gender: '',
    Mobile: '',
    Language: ''
  };
  lang:string[] = ['E', 'C', 'S'];
  validatePass (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('MemberInfo.EnterUserName') as string));
    } else {
      callback();
    }
  }
  validatePass2 (rule, value, callback) {
    if (value === '') {
      callback(new Error(this.$t('MemberInfo.EnterComfirmPwd') as string));
    } else if (value !== this.pwdForm.NewPassword) {
      callback(new Error(this.$t('MemberInfo.PwdNotCorrect') as string));
    } else {
      callback();
    }
  }
  get rules () {
    return {
      FirstName: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserName'),
          trigger: 'blur'
        }
      ],
      LastName: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserLastName'),
          trigger: 'blur'
        }
      ],
      ContactNumber: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterUserPhone'),
          trigger: 'blur'
        }
      ]
    };
  }
  pwdForm: any = {
    Password: '',
    NewPassword: '',
    ConfirmNewPassword: ''
  };
  get pwdRules () {
    var that = this;
    return {
      Password: [
        {
          required: true,
          message: this.$t('MemberInfo.EnterOldPwd'),
          trigger: 'blur'
        }
      ],
      NewPassword: [
        {
          required: true,
          message: '',
          trigger: 'blur',
          validator: that.validatePass
        }
      ],
      ConfirmNewPassword: [
        {
          required: true,
          message: '',
          trigger: 'blur',
          validator: that.validatePass2
        }
      ]
    };
  }
  getProfile () {
    let _this = this;
    this.$Api.member.getProfile().then(
      (data) => {
        if (data) {
          _this.bd = new Date(data.BirthDateString);
          _this.ruleForm = data;
          _this.$store.dispatch('setUser', (data.FirstName + ' ' + data.LastName).toUpperCase());
          _this.$i18n.locale = _this.lang[data.Language];
          this.$HiddenLayer();
        } else {
          _this.$store.dispatch('Logout');
        }
      },
      (data) => {
        this.$HiddenLayer();
        _this.$message({
          message: data,
          type: 'error'
        });
      }
    );
  }
  created () {
    this.getProfile();
  }
  handleClick (tab, event) {
    if (tab.name === 'third') {
      // this.$router.push('/account/deliveryAddress');
      this.$emit('change', 'deliverAddress');
    }
  }
  // 提交用户信息表单
  SubmitForm (ruleForm) {
    let that = this;
    this.$nextTick(function () {
      (that.$refs.ruleForm as ElForm).validate(valid => {
        if (valid) {
          if (!(this.bd instanceof Date)) {
            this.ruleForm.BirthDate = this.bd;
            let bd = (this.bd as any).split('-');
            if (bd.length > 2) this.ruleForm.BirthMD = bd[1] + '/' + bd[2];
            this.ruleForm.BirthDateString = this.ruleForm.BirthDate.replace(/-/g, '/');
          } else {
            let year = this.bd.getFullYear();
            let month = String(this.bd.getMonth() + 1);
            let day = this.bd.getDate();
            if (month.length > 1) month = '0' + month;
            this.ruleForm.BirthDate = year + '-' + month + '-' + day;
            this.ruleForm.BirthMD = month + '/' + day;
            this.ruleForm.BirthDateString = this.ruleForm.BirthDate.replace(/-/g, '/');
          }
          this.$Api.member.updateProfile(this.ruleForm).then(
            function (response) {
              that.$message({ message: that.$t('Message.SucceedInOperating') as string, type: 'success' });
              that.getProfile();
              that.$i18n.locale = that.lang[that.ruleForm.Language];
              // this.$Storage.set('locale', this.langs[this.member.Language]);
              if (that.lang[that.ruleForm.Language] !== that.$Storage.get('locale')) {
                that.$Api.member
                  .setUILanguage(that.ruleForm.Language)
                  .then(result => {
                    that.$i18n.locale = that.lang[that.ruleForm.Language];
                    that.$Storage.set('locale', that.lang[that.ruleForm.Language]);
                    that.Reload();
                  })
                  .catch(error => {
                    console.log(error);
                  });
              }
            },
            function (response) {
              that.$message({
                message: response.Message,
                type: 'error'
              });
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    });
  }
  // 重置用户信息表单
  ResetForm (ruleForm) {
    (this.$refs.ruleForm as ElForm).resetFields();
  }
  // 提交密码修改表单
  CheckPwdSubmit (pwdForm) {
    let that = this;
    this.$nextTick(function () {
      (that.$refs.pwdForm as ElForm).validate(valid => {
        if (valid) {
          this.$Api.member.updatePassword(this.pwdForm).then(
            function (response) {
              console.log(response.Message, '22222');
              that.$message({
                message: response.Message,
                type: 'success'
              });

            // _this.$router.push('/account/registerSuccess');
            },
            function (response) {
              console.log(response.Message, '3333333333333');
              that.$message({
                message: response.Message,
                type: 'error'
              });
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    });
  }
  // 重置用户密码表单
  ResetPwdForm (pwdForm) {
    (this.$refs.pwdForm as ElForm).resetFields();
  }
}
