















import { Vue, Prop, Component } from 'vue-property-decorator';
import InsInput2 from '@/components/base/pc/InsInput2.vue';
import InsButton from '@/components/base/pc/InsButton.vue';
import InsForm from '@/components/base/pc/InsForm.vue';
@Component({ components: { InsInput2, InsButton, InsForm } })
export default class InsResetPSW extends Vue {
    private password:string = '';
    private confirmPassword:string = '';
    none = '';
    s () {
      this.$Api.member.updatePwdFM(this.id, this.code, this.confirmPassword).then((result) => {
        this.$Confirm(this.$t('Message.Message'), result.Message, () => { window.location.href = ('/account/login'); }, () => { window.location.href = ('/account/login'); });
      });
    }
    get id () {
      return this.$route.params.id;
    }
    get code () {
      return this.$route.params.code;
    }
}
